/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js
 * - /npm/swiper@10.3.1/swiper-bundle.min.js
 * - /gh/adactio/FitText.js@master/fittext.min.js
 * - /npm/gsap@3.12.2/dist/gsap.min.js
 * - /npm/hyperscript.org@0.9.11/dist/_hyperscript.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
